import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { OverlayContext } from "../../contexts/OverlayContext";

const OverlayTab = () => {
  const { overlayState, setOverlayState } = useContext(OverlayContext);

  const toggleOverlay = () => {
    setOverlayState((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
    }));
  };

  return (
    <div
      className={`fixed top-1/2 right-0 transform -translate-y-1/2 z-50 transition-opacity duration-300 
                ${overlayState.isOpen ? "opacity-25 pointer-events-none" : "opacity-100"}`}
    >
      <button
        onClick={toggleOverlay}
        className="bg-gray-800 text-white py-2 px-4 rounded-l-lg hover:bg-teal-500 
                 transition-all duration-300 shadow-xl"
      >
        <FontAwesomeIcon icon={faStickyNote} className="text-teal-400" />
        <span className="ml-2">Tools</span>
      </button>
    </div>
  );
};

export default OverlayTab;
