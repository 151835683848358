// src/config.js
const config = {
  production: {
    API_URL: "https://smithtech.io",
  },
  development: {
    API_URL: "http://localhost:8080",
  },
};

const environment = process.env.NODE_ENV || "development";

export const { API_URL } = config[environment];
