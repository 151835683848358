import React, { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "./UserContext";
import useChargeSheets from "../hooks/useChargeSheets"; // Import the custom hook

// Define the context with default values
const ChargeSheetContext = createContext({
  chargeSheets: [],
  activeChargeSheet: null,
  setActiveChargeSheetInfo: () => {},
  removeChargeSheet: () => {},
});

export const ChargeSheetProvider = ({ children }) => {
  const { user } = useUser();
  const { chargeSheets, loading, error, fetchChargeSheets } = useChargeSheets(); // Use the custom hook
  const [activeChargeSheet, setActiveChargeSheet] = useState(null);

  // Set the active charge sheet
  const setActiveChargeSheetInfo = (chargeSheet) => {
    setActiveChargeSheet(chargeSheet);
  };

  // Remove a charge sheet
  const removeChargeSheet = (chargeSheetId) => {
    console.warn("removeChargeSheet function is not implemented to modify chargeSheets directly.");
  };

  // Fetch charge sheets when the user changes
  useEffect(() => {
    if (user) {
      fetchChargeSheets();
    }
  }, [user, fetchChargeSheets]);

  return (
    <ChargeSheetContext.Provider
      value={{
        chargeSheets,
        activeChargeSheet,
        setActiveChargeSheetInfo,
        loading,
        error,
        removeChargeSheet,
      }}
    >
      {children}
    </ChargeSheetContext.Provider>
  );
};

// Custom hook to use the ChargeSheetContext
export const useChargeSheet = () => useContext(ChargeSheetContext);

export default ChargeSheetProvider;
