import React, { useState, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./contexts/UserContext";
import { OverlayProvider } from "./contexts/OverlayContext";
import { ChargeSheetProvider } from "./contexts/ChargeSheetContext";
import { ActiveHospitalLocationProvider } from "./contexts/ActiveHospitalLocationContext";
import { ChargeSheetDetailsProvider } from "./contexts/ChargeSheetDetailsContext";
import Drawer from "./components/navigation/Drawer";
import TabbedOverlay from "./components/overlays/TabbedOverlay";
import OverlayTab from "./components/overlays/OverlayTab";
import "./App.css";

// Lazy load components with better naming and error boundaries
const MainContent = lazy(() => import("./MainContent"));
const WebsiteComponents = {
  LandingPage: lazy(() => import("./Website/LandingPage")),
  AboutUs: lazy(() => import("./Website/AboutUs")),
  ContactUs: lazy(() => import("./Website/ContactUs")),
  FAQ: lazy(() => import("./Website/FAQ")),
  ConsultingServices: lazy(() => import("./Website/ConsultingPage/ConsultingServices")),
  Login: lazy(() => import("./components/login/Login"))
};

// Loading component
const LoadingFallback = () => (
  <div className="min-h-screen flex items-center justify-center bg-gray-900">
    <div className="animate-pulse text-teal-400">Loading...</div>
  </div>
);

function App() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <Router>
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<WebsiteComponents.LandingPage />} />
          <Route path="/about-us" element={<WebsiteComponents.AboutUs />} />
          <Route path="/contact-us" element={<WebsiteComponents.ContactUs />} />
          <Route path="/faq" element={<WebsiteComponents.FAQ />} />
          <Route path="/consulting" element={<WebsiteComponents.ConsultingServices />} />

          {/* Protected routes with /app prefix */}
          <Route
            path="/app/*"
            element={
              <UserProvider>
                <ChargeSheetProvider>
                  <ActiveHospitalLocationProvider>
                    <ChargeSheetDetailsProvider>
                      <OverlayProvider>
                        <div className="app-container bg-gray-900">
                          <Drawer
                            isOpen={isDrawerOpen}
                            toggleDrawer={() => setDrawerOpen(!isDrawerOpen)}
                          />
                          <div
                            className={`content-wrapper ${
                              isDrawerOpen ? "ml-64" : "ml-16"
                            }`}
                          >
                            <Routes>
                              <Route path="login" element={<WebsiteComponents.Login />} />
                              <Route path="/*" element={<MainContent toggleDrawer={() => setDrawerOpen(!isDrawerOpen)} />} />
                            </Routes>
                          </div>
                          <TabbedOverlay />
                          <OverlayTab />
                        </div>
                      </OverlayProvider>
                    </ChargeSheetDetailsProvider>
                  </ActiveHospitalLocationProvider>
                </ChargeSheetProvider>
              </UserProvider>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
