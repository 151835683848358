import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useUser } from "../../contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faChevronDown,
  faChevronUp,
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import { API_URL } from "../../config";
import { motion, AnimatePresence } from "framer-motion";

function ChargeSheetDetails({ userChargeSheetID, details }) {
  const { token, user } = useUser();
  const [chargeSheetDetails, setChargeSheetDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedCharges, setExpandedCharges] = useState({});

  const fetchCharges = useCallback(async () => {
    setLoading(true);
    try {
      const chargesPromises = details.map(async (detail) => {
        const response = await fetch(`${API_URL}/api/get-charges`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            systemID: detail.SystemID,
            locationID: detail.LocationID,
            chargeID: detail.ChargeID,
          }),
        });
        const chargesData = await response.json();
        return { ...detail, Charges: chargesData };
      });

      const detailsWithCharges = await Promise.all(chargesPromises);
      setChargeSheetDetails(detailsWithCharges);
    } catch (error) {
      setError(`Failed to fetch charges: ${error.toString()}`);
    } finally {
      setLoading(false);
    }
  }, [details, token]);

  useEffect(() => {
    fetchCharges();
  }, [fetchCharges]);

  const handleDeleteCharge = async (chargeSheetDetailsID) => {
    if (!window.confirm("Are you sure you want to delete this charge?")) {
      return;
    }

    setLoading(true);
    setError(null);

    const payload = {
      ChargeSheetDetailsID: chargeSheetDetailsID,
      UserChargeSheetID: userChargeSheetID,
      UserID: user.id,
    };

    try {
      const response = await fetch(`${API_URL}/api/delete-chargesheet-detail`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        setError(
          `Failed to delete charge sheet detail: ${response.status}, ${errorText}`,
        );
        return;
      }

      setChargeSheetDetails((prevDetails) =>
        prevDetails.filter(
          (detail) => detail.ChargeSheetDetailsID !== chargeSheetDetailsID,
        ),
      );
    } catch (error) {
      setError(`An error occurred: ${error.toString()}`);
    } finally {
      setLoading(false);
    }
  };

  const toggleExpandCharge = (chargeID) => {
    setExpandedCharges((prev) => ({
      ...prev,
      [chargeID]: !prev[chargeID],
    }));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8 bg-gray-800/50 rounded-xl">
        <ClipLoader color="#14B8A6" loading={true} size={40} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center gap-2 text-red-400 bg-red-400/10 p-4 rounded-xl">
        <FontAwesomeIcon icon={faExclamationCircle} />
        <p>{error}</p>
      </div>
    );
  }

  if (chargeSheetDetails.length === 0) {
    return (
      <div className="flex items-center justify-center gap-2 text-gray-400 
                    bg-gray-800/50 p-4 rounded-xl border border-gray-700/50">
        <FontAwesomeIcon icon={faInfoCircle} />
        <p>No details available for this charge sheet.</p>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-4"
    >
      {chargeSheetDetails.map((detail) => (
        <motion.div
          key={detail.ChargeSheetDetailsID}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="p-4 bg-gray-800/50 backdrop-blur-sm rounded-xl shadow-lg
                   border border-gray-700/50 hover:border-gray-600/50"
        >
          {detail.Charges?.length > 0 ? (
            <div className="space-y-4">
              {detail.Charges.map((charge) => (
                <div
                  key={charge.ChargeID}
                  className="bg-gray-700/50 p-4 rounded-lg transition-all duration-200
                           hover:bg-gray-700/70"
                >
                  <div className="flex justify-between items-start gap-4">
                    <div className="flex-1 space-y-2">
                      <p className="text-lg font-semibold text-teal-400 break-words">
                        {charge.ServiceDescription}
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                        {charge.BillingCode && (
                          <p className="text-sm text-gray-300">
                            <span className="font-medium">Billing Code:</span>{" "}
                            {charge.BillingCode}
                          </p>
                        )}
                        {charge.LocationName && (
                          <p className="text-sm text-gray-300">
                            <span className="font-medium">Location:</span>{" "}
                            {charge.LocationName}
                          </p>
                        )}
                      </div>
                    </div>
                    
                    <div className="flex items-center gap-2">
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => handleDeleteCharge(detail.ChargeSheetDetailsID)}
                        className="bg-red-500/80 hover:bg-red-600 text-white p-2 
                                 rounded-lg transition-all duration-200"
                        aria-label="Delete charge"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => toggleExpandCharge(charge.ChargeID)}
                        className="bg-gray-600/50 hover:bg-gray-600 text-teal-400 
                                 hover:text-teal-300 p-2 rounded-lg transition-all duration-200"
                        aria-label={expandedCharges[charge.ChargeID] ? "Collapse details" : "Expand details"}
                      >
                        <FontAwesomeIcon
                          icon={expandedCharges[charge.ChargeID] ? faChevronUp : faChevronDown}
                        />
                      </motion.button>
                    </div>
                  </div>

                  <AnimatePresence>
                    {expandedCharges[charge.ChargeID] && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="mt-4 border-t border-gray-600/50 pt-4"
                      >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                          {Object.entries(charge).map(([key, value]) => {
                            if (
                              ["LocationID", "SystemID", "ChargeID", "IsDeleted",
                               "ChargeSheetDetailsID", "UserChargeSheetID", "UserID",
                               "ChargeCode", "ServiceDescription", "BillingCode",
                               "LocationName"].includes(key) ||
                              value === null ||
                              typeof value === "object"
                            ) {
                              return null;
                            }
                            return (
                              <p key={key} className="text-sm text-gray-300">
                                <span className="font-medium text-teal-400">{key}:</span>{" "}
                                {value}
                              </p>
                            );
                          })}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-gray-400 bg-gray-700/30 p-3 rounded-lg text-center">
              No charges available for this detail.
            </div>
          )}
        </motion.div>
      ))}
    </motion.div>
  );
}

ChargeSheetDetails.propTypes = {
  userChargeSheetID: PropTypes.string.isRequired,
  details: PropTypes.array.isRequired,
};

export default ChargeSheetDetails;
