import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useUser } from "../../contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

const NoteCard = ({ note, onDelete, onEdit }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    className="bg-gray-700 p-4 rounded-xl mb-4 shadow-md"
  >
    <textarea
      value={note.content}
      onChange={(e) => onEdit(note.id, e.target.value)}
      className="w-full bg-gray-800 text-gray-300 resize-none focus:outline-none rounded p-2"
      rows={4}
    />
    <div className="flex justify-end mt-2">
      <button
        onClick={() => onDelete(note.id)}
        className="text-red-400 hover:text-red-300 transition-colors duration-200"
        aria-label="Delete note"
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </div>
  </motion.div>
);

const Notepad = () => {
  const { user, token } = useUser();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotes = async () => {
      if (!user?.userID) return;

      try {
        const response = await fetch(`https://smithtech.io/api/notes/${user.userID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) throw new Error("Failed to fetch notes");
        const data = await response.json();
        setNotes(data);
      } catch (err) {
        setError("Failed to load notes");
        console.error("Note fetch error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchNotes();
  }, [user?.userID, token]);

  const addNote = async () => {
    if (!user?.userID) return;

    const newNote = {
      id: Date.now(),
      content: "",
      userId: user.userID,
      timestamp: new Date().toISOString(),
    };

    try {
      const response = await fetch("https://smithtech.io/api/notes", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newNote),
      });

      if (!response.ok) throw new Error("Failed to add note");
      const data = await response.json();
      setNotes([...notes, data]);
    } catch (err) {
      setError("Failed to add note");
      console.error("Note add error:", err);
    }
  };

  const deleteNote = async (id) => {
    if (!user?.userID) return;

    try {
      const response = await fetch(`https://smithtech.io/api/notes/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) throw new Error("Failed to delete note");
      const updatedNotes = notes.filter((note) => note.id !== id);
      setNotes(updatedNotes);
    } catch (err) {
      setError("Failed to delete note");
      console.error("Note delete error:", err);
    }
  };

  const editNote = async (id, content) => {
    if (!user?.userID) return;

    try {
      const response = await fetch(`https://smithtech.io/api/notes/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content }),
      });

      if (!response.ok) throw new Error("Failed to edit note");
      const updatedNotes = notes.map((note) => (note.id === id ? { ...note, content } : note));
      setNotes(updatedNotes);
    } catch (err) {
      setError("Failed to edit note");
      console.error("Note edit error:", err);
    }
  };

  return (
    <div className="notepad-container bg-gray-800 p-6 flex flex-col justify-center items-center min-h-screen rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-teal-400 mb-6">Note Taking!</h1>
      <form onSubmit={addNote} className="mb-4 space-y-4 w-full max-w-md">
        <input
          name="title"
          type="text"
          placeholder="Note Title"
          className="block w-full px-3 py-2 bg-gray-900 border border-gray-600 rounded-md shadow-sm text-gray-300 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-teal-500 transition duration-300"
        />
        <textarea
          name="content"
          placeholder="Free Text Area"
          className="block w-full px-3 py-2 bg-gray-900 border border-gray-600 rounded-md shadow-sm text-gray-300 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-teal-500 transition duration-300"
        />
        <button
          type="submit"
          className="w-full px-4 py-2 bg-teal-600 hover:bg-teal-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 transition ease-in duration-200"
        >
          Add Note
        </button>
      </form>
      <div className="notes-container flex flex-wrap justify-center gap-4">
        {notes.map((note) => (
          <NoteCard
            key={note.id}
            note={note}
            onDelete={deleteNote}
            onEdit={editNote}
          />
        ))}
      </div>
    </div>
  );
};

export default Notepad;
