import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { useUser } from "./UserContext";
import { useChargeSheet } from "./ChargeSheetContext";
import { API_URL } from "../config";

const ChargeSheetDetailsContext = createContext({
  activeChargeSheetDetails: [],
  updateChargeSheetDetails: () => {},
  addChargeSheetDetail: () => {},
});

export const ChargeSheetDetailsProvider = ({ children }) => {
  const [activeChargeSheetDetails, setActiveChargeSheetDetails] = useState([]);
  const { activeChargeSheet } = useChargeSheet();
  const { user, token } = useUser();

  const fetchActiveChargeSheetDetails = useCallback(async () => {
    if (!activeChargeSheet || !activeChargeSheet.UserChargeSheetID) return;

    try {
      const response = await fetch(`${API_URL}/api/get-chargesheet-charges`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userID: user.id,
          userChargeSheetID: activeChargeSheet.UserChargeSheetID,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch charge sheet details");
      }

      const chargesData = await response.json();
      setActiveChargeSheetDetails(chargesData);
    } catch (error) {
      console.error("Failed to fetch active charge sheet details:", error);
    }
  }, [activeChargeSheet, user.id, token]);

  useEffect(() => {
    if (activeChargeSheet) {
      fetchActiveChargeSheetDetails();
    } else {
      setActiveChargeSheetDetails([]);
    }
  }, [activeChargeSheet, fetchActiveChargeSheetDetails]);

  const updateChargeSheetDetails = (details) => {
    setActiveChargeSheetDetails(details);
  };

  const addChargeSheetDetail = async (newDetail) => {
    setActiveChargeSheetDetails((prevDetails) => [...prevDetails, newDetail]);
    fetchActiveChargeSheetDetails();
  };

  return (
    <ChargeSheetDetailsContext.Provider
      value={{
        activeChargeSheetDetails,
        updateChargeSheetDetails,
        addChargeSheetDetail,
      }}
    >
      {children}
    </ChargeSheetDetailsContext.Provider>
  );
};

export const useChargeSheetDetails = () =>
  useContext(ChargeSheetDetailsContext);

ChargeSheetDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChargeSheetDetailsProvider;
