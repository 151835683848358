// src/hooks/useChargeSheets.js
import { useState, useEffect, useCallback } from "react";
import { useUser } from "../contexts/UserContext";
import { API_URL } from "../config";

const useChargeSheets = () => {
  const { user, token } = useUser();
  const [chargeSheets, setChargeSheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchChargeSheets = useCallback(async () => {
    if (!user?.id) {
      setError("User not logged in or invalid user ID");
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/user-chargesheets?userID=${user.id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch charge sheets: ${response.status}`);
      }

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Received non-JSON response from server");
      }

      const data = await response.json();
      setChargeSheets(data || []);
    } catch (err) {
      setError(err.message);
      console.error("Error fetching charge sheets:", err);
    } finally {
      setLoading(false);
    }
  }, [user?.id, token]);

  useEffect(() => {
    fetchChargeSheets();
  }, [fetchChargeSheets]);

  return { chargeSheets, loading, error, fetchChargeSheets };
};

export default useChargeSheets;