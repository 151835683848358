import React, { useState } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import Notepad from "../notepad/Notepad";
import OverlayChargeSheet from "../../components/ChargeSheetHome/OverlayChargeSheet";
import { useChargeSheetDetails } from "../../contexts/ChargeSheetDetailsContext";
import { useUser } from "../../contexts/UserContext";

const TabbedOverlay = ({ isOpen, closeOverlay }) => {
  const [activeTab, setActiveTab] = useState("notepad");
  const { user } = useUser();
  const { activeChargeSheetDetails } = useChargeSheetDetails();

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 bg-gray-900/60 flex justify-end"
      >
        <motion.div 
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ type: "tween", duration: 0.3 }}
          className="w-full max-w-md h-full bg-gray-800 shadow-lg overflow-hidden relative flex flex-col"
        >
          <div className="flex justify-between items-center p-4 bg-gray-900">
            <h2 className="text-lg font-semibold text-teal-400">
              Tools
            </h2>
            <button
              onClick={closeOverlay}
              className="text-white bg-red-500 hover:bg-red-600 px-3 py-1 rounded 
                       transition-colors duration-300"
              aria-label="Close overlay"
            >
              Close
            </button>
          </div>

          <div className="flex justify-around bg-gray-700">
            {["notepad", "chargesheet"].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`py-2 px-4 transition-colors duration-300 capitalize
                  ${activeTab === tab 
                    ? "bg-gray-800 text-teal-400" 
                    : "text-gray-300 hover:bg-gray-800 hover:text-teal-400"}`}
                aria-selected={activeTab === tab}
              >
                {tab}
              </button>
            ))}
          </div>

          <div className="p-4 flex-grow overflow-y-auto bg-gray-800">
            {activeTab === "notepad" && <Notepad />}
            {activeTab === "chargesheet" && <OverlayChargeSheet />}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

TabbedOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeOverlay: PropTypes.func.isRequired,
};

export default TabbedOverlay;
