import React, { useEffect, useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ChargeSheetDetails from "./ChargeSheetDetails";
import { useChargeSheet } from "../../contexts/ChargeSheetContext";
import { useChargeSheetDetails } from "../../contexts/ChargeSheetDetailsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { OverlayContext } from "../../contexts/OverlayContext";
import { useUser } from "../../contexts/UserContext";
import CreateChargeSheet from "./CreateChargeSheet";
import { API_URL } from "../../config";

const OverlayChargeSheet = () => {
  const { user, token } = useUser();
  const { activeChargeSheet, setActiveChargeSheetInfo } = useChargeSheet();
  const { activeChargeSheetDetails, updateChargeSheetDetails } =
    useChargeSheetDetails();
  const { setOverlayState } = useContext(OverlayContext);
  const [groupedDetails, setGroupedDetails] = useState({});
  const [systems, setSystems] = useState({});
  const [locations, setLocations] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedSystems, setExpandedSystems] = useState({});
  const [expandedLocations, setExpandedLocations] = useState({});
  const [expandAll, setExpandAll] = useState(false);
  const [userChargeSheets, setUserChargeSheets] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (activeChargeSheetDetails && activeChargeSheetDetails.length > 0) {
      fetchDetails(activeChargeSheetDetails);
    }
  }, [activeChargeSheetDetails]);

  useEffect(() => {
    fetchUserChargeSheets();
  }, [token]);

  const fetchDetails = useCallback(async (details) => {
    setLoading(true);
    try {
      const systemsResponse = await fetch(`${API_URL}/api/systems-info`);
      const systemsData = await systemsResponse.json();
      setSystems(
        systemsData.reduce((acc, system) => {
          acc[system.SystemID] = system.SystemName;
          return acc;
        }, {}),
      );

      const locationsResponse = await fetch(
        `${API_URL}/api/hospital-locations`,
      );
      const locationsData = await locationsResponse.json();
      setLocations(locationsData);

      const grouped = groupDetailsBySystemAndLocation(details, locationsData);
      setGroupedDetails(grouped);
    } catch (e) {
      setError(`Failed to fetch data: ${e.toString()}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUserChargeSheets = async () => {
    setLoading(true);
    try {
      const apiUrl = `${API_URL}/api/user-chargesheets?userID=${user.id}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        setError(
          `Failed to fetch charge sheets: ${response.status}, ${errorText}`,
        );
        return;
      }

      const data = await response.json();
      setUserChargeSheets(data || []);
    } catch (err) {
      setError(`An error occurred: ${err.toString()}`);
    } finally {
      setLoading(false);
    }
  };

  const groupDetailsBySystemAndLocation = (details, locationsData) => {
    return details.reduce((acc, detail) => {
      const { SystemID, LocationID } = detail;
      if (!acc[SystemID]) {
        acc[SystemID] = { locations: {} };
      }
      if (!acc[SystemID].locations[LocationID]) {
        acc[SystemID].locations[LocationID] = {
          locationName:
            locationsData[SystemID]?.[LocationID] || "Unknown Location",
          details: [],
        };
      }
      acc[SystemID].locations[LocationID].details.push(detail);
      return acc;
    }, {});
  };

  const toggleExpandSystem = (systemID) => {
    setExpandedSystems((prev) => ({
      ...prev,
      [systemID]: !prev[systemID],
    }));
  };

  const toggleExpandLocation = (locationID) => {
    setExpandedLocations((prev) => ({
      ...prev,
      [locationID]: !prev[locationID],
    }));
  };

  const toggleExpandAll = () => {
    setExpandAll(!expandAll);
    const newExpandedSystems = {};
    const newExpandedLocations = {};

    Object.keys(groupedDetails).forEach((systemID) => {
      newExpandedSystems[systemID] = !expandAll;
      Object.keys(groupedDetails[systemID].locations).forEach((locationID) => {
        newExpandedLocations[locationID] = !expandAll;
      });
    });

    setExpandedSystems(newExpandedSystems);
    setExpandedLocations(newExpandedLocations);
  };

  const handleNavigateToChargeSheets = () => {
    setOverlayState({ isOpen: false, activeTab: "notepad" });
    navigate("/chargesheets");
  };

  const handleCreateChargeSheet = (newChargeSheet) => {
    setActiveChargeSheetInfo(newChargeSheet);
  };

  const handleSelectChargeSheet = async (event) => {
    const selectedSheetId = event.target.value;
    const selectedSheet = userChargeSheets.find(
      (sheet) => sheet.UserChargeSheetID === parseInt(selectedSheetId, 10),
    );
    if (selectedSheet) {
      setActiveChargeSheetInfo(selectedSheet);
      try {
        const apiUrl = `${API_URL}/api/chargesheet-details?userChargeSheetID=${selectedSheet.UserChargeSheetID}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          setError(
            `Failed to fetch charge sheet details: ${response.status}, ${errorText}`,
          );
          return;
        }

        const details = await response.json();
        updateChargeSheetDetails(details); // Update context with new details
        fetchDetails(details); // Fetch details for the selected charge sheet
      } catch (err) {
        setError(`An error occurred: ${err.toString()}`);
      }
    }
  };

  return (
    <div className="h-full bg-gray-800 text-white p-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold text-teal-400">Charge Sheets</h2>
        <Link
          to="/app/chargesheets"
          className="bg-teal-500 hover:bg-teal-600 text-white px-4 py-2 rounded 
                   transition-colors duration-200 text-sm font-medium"
        >
          View All Charge Sheets
        </Link>
      </div>

      {!activeChargeSheet && (
        <div className="mb-4">
          <label 
            htmlFor="chargeSheetSelect"
            className="block text-sm font-medium text-gray-300 mb-2"
          >
            Select Charge Sheet
          </label>
          <select
            id="chargeSheetSelect"
            onChange={handleSelectChargeSheet}
            className="w-full p-2 bg-gray-700 text-white rounded-md border border-gray-600 
                     focus:ring-2 focus:ring-teal-500 focus:border-transparent"
            value={activeChargeSheet ? activeChargeSheet.UserChargeSheetID : ""}
          >
            <option value="" disabled>Select a Charge Sheet</option>
            {userChargeSheets.map((sheet) => (
              <option key={sheet.UserChargeSheetID} value={sheet.UserChargeSheetID}>
                {sheet.ChargeSheetNameDefault} 
                {sheet.CreatedAt && ` - Created: ${new Date(sheet.CreatedAt).toLocaleDateString()}`}
              </option>
            ))}
          </select>
        </div>
      )}

      {activeChargeSheet && (
        <div className="mt-4">
          <h2 className="text-lg font-semibold text-teal-400">
            {activeChargeSheet.ChargeSheetNameDefault}
          </h2>
        </div>
      )}

      {activeChargeSheet && activeChargeSheetDetails && (
        <>
          <button
            onClick={toggleExpandAll}
            className="bg-teal-500 hover:bg-teal-600 text-white font-semibold py-2 px-4 
                     rounded transition-colors duration-200 mt-2 mb-4"
          >
            {expandAll ? "Collapse All" : "Expand All"}
          </button>

          {loading && (
            <div className="text-gray-300">Loading details...</div>
          )}

          {error && (
            <div className="text-red-400 bg-red-400/10 p-3 rounded-md">
              Error: {error}
            </div>
          )}

          {Object.entries(groupedDetails).map(([systemID, { locations }]) => (
            <div key={systemID} className="my-2">
              <div
                className="cursor-pointer bg-gray-700 hover:bg-gray-600 text-white p-3 
                         rounded-lg transition-colors duration-200"
                onClick={() => toggleExpandSystem(systemID)}
              >
                <FontAwesomeIcon
                  icon={expandedSystems[systemID] ? faChevronUp : faChevronDown}
                  className="mr-2 text-teal-400"
                />
                {systems[systemID] || "Unknown System"}
              </div>

              {expandedSystems[systemID] && (
                Object.entries(locations).map(([locationID, locationData]) => (
                  <div key={locationID} className="ml-4 my-2">
                    <div
                      className="cursor-pointer bg-gray-700 hover:bg-gray-600 text-white 
                               p-2 rounded-lg transition-colors duration-200"
                      onClick={() => toggleExpandLocation(locationID)}
                    >
                      <FontAwesomeIcon
                        icon={expandedLocations[locationID] ? faChevronUp : faChevronDown}
                        className="mr-2 text-teal-400"
                      />
                      {locationData.locationName}
                    </div>

                    {expandedLocations[locationID] && (
                      <ChargeSheetDetails
                        userChargeSheetID={locationData.details[0].UserChargeSheetID}
                        details={locationData.details}
                      />
                    )}
                  </div>
                ))
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

OverlayChargeSheet.propTypes = {
  token: PropTypes.string.isRequired,
};

export default OverlayChargeSheet;
