export const colorMapping = {
  1: "#a5a7d4",
  2: "#a30046",
  3: "#2361fd",
  4: "#63599e",
  5: "#006937",
  6: "#800000",
};

export const systemMapping = [
  { id: 1, name: "Advocate" },
  { id: 2, name: "Loyola" },
  { id: 3, name: "NorthShore" },
  { id: 4, name: "NorthWestern" },
  { id: 5, name: "Rush" },
  { id: 6, name: "University of Chicago" },
  { id: 7, name: "Kindred" },
];

export const userTypeMapping = {
  1: "Admin",
  2: "Tester",
  3: "Free",
  4: "Subscriber",
};


export const USER_TYPES = {
  Admin: 1,
  Tester: 2,
  Free: 3,
  Subscriber: 4,
};
