import React, { useState } from "react";
import PropTypes from "prop-types";
import { useUser } from "../../contexts/UserContext";
import Toast from "../Toast/Toast";
import { API_URL } from "../../config";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function CreateChargeSheet({ onCreateChargeSheet }) {
  const { user, token } = useUser();
  const [chargeSheetName, setChargeSheetName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleCreateChargeSheet = async () => {
    if (!chargeSheetName) {
      setError("Charge sheet name is required.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const apiUrl = `${API_URL}/api/create-user-chargesheet`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserID: user.id,
          CreatedAt: new Date().toISOString(),
          ChargeSheetNameDefault: chargeSheetName,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        setError(
          `Failed to create charge sheet: ${response.status}, ${errorText}`,
        );
        return;
      }

      const newChargeSheet = await response.json();
      newChargeSheet.ChargeSheetNameDefault = chargeSheetName; // Ensure the correct name is set
      setToastMessage("Charge sheet created successfully!");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setChargeSheetName("");
        onCreateChargeSheet(newChargeSheet); // Pass the new charge sheet to the callback
      }, 2000); // Adjust the timeout as needed
    } catch (err) {
      setError(`An error occurred: ${err.toString()}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="w-full mb-6 p-6 bg-gray-800 rounded-xl shadow-lg"
    >
      <h2 className="text-xl font-semibold mb-4 text-teal-400">
        Create New Charge Sheet
      </h2>
      <div className="space-y-4">
        <input
          type="text"
          value={chargeSheetName}
          onChange={(e) => {
            setChargeSheetName(e.target.value);
            setError(null);
          }}
          maxLength={50}
          placeholder="Enter charge sheet name"
          className="w-full px-4 py-2 rounded-lg bg-gray-700/50 border border-gray-600 
                   text-white placeholder-gray-400 focus:outline-none focus:border-teal-500 
                   focus:ring-2 focus:ring-teal-500/20 transition-all duration-200"
        />
        <button
          onClick={handleCreateChargeSheet}
          disabled={loading || !chargeSheetName}
          className={`w-full flex items-center justify-center gap-2 px-4 py-2 rounded-lg
                    font-medium transition-all duration-200 ${
                      loading || !chargeSheetName
                        ? "bg-gray-700 text-gray-400"
                        : "bg-teal-500 text-white hover:bg-teal-600"
                    }`}
        >
          {loading ? (
            <AnimatePresence>
              <FontAwesomeIcon
                icon={faPlus}
                className="animate-spin"
                size="lg"
              />
            </AnimatePresence>
          ) : (
            "Create Charge Sheet"
          )}
        </button>
        {error && <div className="text-red-500 mt-2">{error}</div>}
        {showToast && (
          <Toast message={toastMessage} onClose={() => setShowToast(false)} />
        )}
      </div>
    </motion.div>
  );
}

CreateChargeSheet.propTypes = {
  onCreateChargeSheet: PropTypes.func.isRequired,
};

export default CreateChargeSheet;
