import React, { createContext, useState, useContext } from "react";

const ActiveHospitalLocationContext = createContext();

export const ActiveHospitalLocationProvider = ({ children }) => {
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeSystem, setActiveSystem] = useState(null);

  const setActiveHospitalLocation = (location) => {
    setActiveLocation(location);
  };

  const setActiveHospitalSystem = (system) => {
    setActiveSystem(system);
  };

  return (
    <ActiveHospitalLocationContext.Provider
      value={{
        activeLocation,
        setActiveHospitalLocation,
        activeSystem,
        setActiveHospitalSystem,
      }}
    >
      {children}
    </ActiveHospitalLocationContext.Provider>
  );
};

export const useActiveHospitalLocation = () =>
  useContext(ActiveHospitalLocationContext);
