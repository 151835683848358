import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Toast = ({ message, onClose, duration = 3000, className = "" }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      setTimeout(onClose, 500); // Additional delay to allow the fade-out transition
    }, duration); // Toast message visible for the specified duration

    return () => clearTimeout(timer);
  }, [onClose, duration]);

  return (
    <div
      role="alert"
      aria-live="assertive"
      className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-gray-900 text-teal-400 py-3 px-6 rounded-lg shadow-lg transition-opacity duration-500 ${
        visible ? "opacity-100" : "opacity-0"
      } ${className}`}
      style={{ zIndex: 10002 }}
    >
      {message}
    </div>
  );
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  duration: PropTypes.number, // Allow the duration to be configurable
  className: PropTypes.string, // Additional class names for custom styling
};

export default Toast;

