import React, { createContext, useContext, useState } from "react";
import { userTypeMapping } from "../components/constants"; // Import the mapping

// Create the UserContext with default values
const UserContext = createContext({
  user: null,
  token: null,
  loginUser: () => { },
  logoutUser: () => { },
});

// UserProvider component to manage and provide user state
export const UserProvider = ({ children }) => {
  // Initialize user state from localStorage
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    try {
      return savedUser ? JSON.parse(savedUser) : null;
    } catch (e) {
      console.error("Error parsing user from localStorage", e);
      return null;
    }
  });

  // Initialize token state from localStorage
  const [token, setToken] = useState(() => localStorage.getItem("token"));

  // Function to handle user login
  const loginUser = ({ token, user }) => {
    // Convert user properties to camelCase
    const formattedUser = {
      id: user.userID,
      firstName: user.userFirstName,
      lastName: user.userLastName,
      email: user.userEmail,
      company: user.userCompany,
      isAuthGS: user.isAuthGS,
      userTypeID: user.userTypeID,
      userTypeName: userTypeMapping[user.userTypeID], // Add UserTypeName based on UserTypeID
    };
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(formattedUser));
    setToken(token);
    setUser(formattedUser);
  };

  // Function to handle user logout
  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setToken(null);
    setUser(null);
  };

  // Providing the context with user, token, and methods to login and logout
  return (
    <UserContext.Provider value={{ user, token, loginUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext);

export default UserProvider;
